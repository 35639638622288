<template>
  <modal-layout @close="$emit('close')" :title="$t('terms')">
    <div class="modal-content">
        <div class="row justify-content-center">
            <h3 v-if="!isLoyalClient && friendBasket">{{ $t("rating.h2") }}</h3>
            <p v-if="!isLoyalClient && friendBasket" style="margin-top: 30px;">
                {{ $t("rating.getBasket.text") }}
            </p>
            
            <h3 v-if="isLoyalClient" style="margin-top:20px;">{{ $t("rating.h2") }}</h3>
            <p v-if="isLoyalClient" style="margin-top: 20px; margin-bottom:30px;">{{ $t("rating.getBasket.loyalText") }}
                <br>
                <span>{{ $t("rating.getBasket.loyalthank") }}</span>
            </p>
        </div>
    </div>
    <button class="bottom-page-btn primary-btn" @click="$emit('close')">{{$t('understand')}}</button>
  </modal-layout>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "LoyalClientModal",
  computed: {
      ...mapGetters({
          isLoyalClient: 'ogpoForm/isLoyalClient',
          friendBasket: 'ogpoForm/friendBasket'
      })
  },
  data() {
    return {
      info: null
    }
  },
  created() {
    this.info = this.$attrs.info;
  },
}
</script>

<style lang="scss">
.bonus-modal__title {
  font-weight: 800;
  font-size: 34px;
}
.bonus-modal__content {
  a {
    color: #2AA65C !important;
    font-size: initial !important;
  }
  h6 {
    font-weight: bold !important;
    font-size: 16px !important;
    margin: 12px 0 !important;
  }
}
</style>
